import Button from "@/Button";
import { ReactSVG } from "@/ReactSVG";
const Maintain = () => [
    <div className="main-maintain" key="main-maintain">
        <div className="page404__header header-wrapper">
            <ReactSVG className="logo" src="/svg/Fun88Logo.svg" />
        </div>
        <div className="maintain-body">
            <div className="maintain-heading">亲爱的客户</div>
            <div className="maintain-desc">
                我们的系统正在升级维护中，请稍后再尝试登入
                您可以通过以下方式联系我们在线客服
            </div>
            <Button className="maintain-cs" onClick={global.PopUpLiveChat}>线上客服</Button>
            <a className="maintain-contact" href="mailto: cs@fun88.com">
                电邮: cs@fun88.com
            </a>
            <a
                style={{ marginBottom: "16px" }}
                className="maintain-contact"
                href="tel:+86 400 842 891"
            >
                热线电话: +86 400 842 891
            </a>
        </div>
    </div>,
];

export default Maintain;
