import React from "react";
import { ReactSVG } from '@/ReactSVG';
import Router from 'next/router';
import Service from './Service';

class BackBar extends React.Component {
	constructor() {
		super();
		this.state = {};

		this.handleBackEvent = this.handleBackEvent.bind(this);
	}
	componentDidMount() {}
	handleBackEvent() {
		if (this.props.backEvent) {
			this.props.backEvent();
		} else {
			history.length ? history.go(-1) : Router.push('/');
		}
	}
	render() {
		return (
			<div className="header-wrapper header-bar">
				<ReactSVG className="back-icon" src="/svg/LeftArrow.svg" onClick={this.handleBackEvent} />
				<span>{this.props.title}</span>
				{this.props.hasServer ? (
					<div
						className="header-tools-wrapper"
						onClick={() => {
							if (Router.router.pathname == '/deposit') {
								Pushgtagdata(`LiveChat`, 'Launch', 'Deposit_CS');
							} else if (Router.router.pathname == '/transfer') {
								Pushgtagdata(`LiveChat`, 'Launch', 'Transfer_CS');
							} else if (Router.router.pathname == '/information') {
								Pushgtagdata(`LiveChat`, 'Launch', 'Notification_CS');
							} else if (Router.router.pathname == '/tutorial/bet') {
								Pushgtagdata(`LiveChat`, 'Launch', 'Bet_Tutorial_CS');
							}
						}}
					>
						<Service />
					</div>
				) : null}
			</div>
		);
	}
}

export default BackBar;
